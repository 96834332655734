import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Recording } from '../types';
import calculateEnergy from '../helper/energy-calculator-utils';
import { useGlobalState } from '../context/StateContext';
import InfoIcon from '@mui/icons-material/Info';

const RecordingsTable: React.FC = () => {
  const [selectedRecordingIndex, setSelectedRecordingIndex] = useState<number | null>(null);
  const [newLabel, setNewLabel] = useState('');
  const [isDataModalOpen, setIsDataModalOpen] = useState(false);
  const [mwh, setMwh] = useState<number>(0);
  const [apiData, setApiData] = useState<{ energy_matches: any[], power_matches: any[] }>({
    energy_matches: [],
    power_matches: [],
  });
  const [requestDuration, setRequestDuration] = useState<number>(0);
  const { recordings, updateRecordings } = useGlobalState();
  const [fieldValue, setFieldValue] = useState<string>('');

  const handleEditClick = (index: number) => {
    setSelectedRecordingIndex(index);
    setNewLabel(recordings[index].label);
  };

  const handleDeleteClick = (index: number) => {
    updateRecordings((prev) =>
      prev.filter((_: Recording, i: number) => i !== index),
    );
  };

  const handleSaveLabel = () => {
    if (selectedRecordingIndex !== null) {
      updateRecordings((prev) => {
        const updatedRecordings = [...prev];
        updatedRecordings[selectedRecordingIndex].label = newLabel;
        return updatedRecordings;
      });
      setSelectedRecordingIndex(null);
    }
  };

  const handleClose = () => {
    setSelectedRecordingIndex(null);
  };

  // const handleDataModalOpen = async (mwh: number) => {
  //   const startTime = performance.now();
  //   try {
  //     const response = await fetch(`http://localhost:9000/energy?mwh=${mwh}`);
  //     const data = await response.json();
  //     const endTime = performance.now();
  //     setRequestDuration(endTime - startTime);
  //     setApiData({
  //       energy_matches: data.energy_matches || [],
  //       power_matches: data.power_matches || [],
  //     });
  //     setMwh(mwh);
  //     setIsDataModalOpen(true);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setApiData({ energy_matches: [], power_matches: [] });
  //   }
  // };

  // const handleDataModalClose = () => {
  //   setIsDataModalOpen(false);
  // };

  const handleDataModalOpen = (mwh: number) => {
    setMwh(mwh);
    setFieldValue(mwh.toFixed(2));
    setIsDataModalOpen(true);
  };

  const handleDataModalClose = () => {
    setIsDataModalOpen(false);
    setFieldValue('');
  };

  const handleApiCall = async () => {
    const startTime = performance.now();
    try {
      const response = await fetch(`/energy?mwh=${fieldValue}`);
      const data = await response.json();
      const endTime = performance.now();
      setRequestDuration(endTime - startTime);
      setApiData({
        energy_matches: data.energy_matches || [],
        power_matches: data.power_matches || [],
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      setApiData({ energy_matches: [], power_matches: [] });
    }
  };


  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell>Start time</TableCell>
              <TableCell>Energy (mWh)</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recordings.map((recording, index) => (
              <TableRow key={index}>
                <TableCell>{recording.label}</TableCell>
                <TableCell>
                  {new Date(recording.recordedData[0].creation).toLocaleString(
                    'fr-FR',
                    {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    },
                  )}
                </TableCell>
                <TableCell>
                  {calculateEnergy(recording.recordedData).toFixed(2)}
                </TableCell>
                <TableCell>
                  {(
                    (new Date(
                        recording.recordedData[
                        recording.recordedData.length - 1
                          ].creation,
                      ).getTime() -
                      new Date(recording.recordedData[0].creation).getTime()) /
                    1000
                  ).toFixed(2)}{' '}
                  seconds
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(index)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteClick(index)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDataModalOpen(calculateEnergy(recording.recordedData))}>
                    <InfoIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={selectedRecordingIndex !== null} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          {selectedRecordingIndex !== null && (
            <>
              <h2>Edit Recording</h2>
              <p>Energy: {calculateEnergy(recordings[selectedRecordingIndex].recordedData).toFixed(2)} mWh</p>
              <p>Duration: {(
                (new Date(recordings[selectedRecordingIndex].recordedData[recordings[selectedRecordingIndex].recordedData.length - 1].creation).getTime() -
                  new Date(recordings[selectedRecordingIndex].recordedData[0].creation).getTime()) / 1000
              ).toFixed(2)} seconds</p>
              <p>Start
                time: {new Date(recordings[selectedRecordingIndex].recordedData[0].creation).toLocaleString('fr-FR', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })}</p>
              <TextField
                fullWidth
                label="Label"
                value={newLabel}
                onChange={(e) => setNewLabel(e.target.value)}
              />
              <Button
                onClick={handleSaveLabel}
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                Save
              </Button>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="secondary"
                sx={{ mt: 2, ml: 2 }}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
      </Modal>
      <Modal open={isDataModalOpen} onClose={handleDataModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2>Energy equivalency</h2>
          <TextField
            fullWidth
            label="Energy (mWh)"
            value={fieldValue}
            onChange={(e) => setFieldValue(e.target.value)}
          />
          <Button
            onClick={handleApiCall}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Fetch Data
          </Button>
          <p>Request duration: {requestDuration.toFixed(2)} ms</p>
          <h3>Equivalent amount of energy</h3>
          <ul>
            {apiData.energy_matches.map((item, index) => (
              <li key={index}>{item.label}: {item.value}</li>
            ))}
          </ul>
          <h3>Equivalent energy consumption</h3>
          <ul>
            {apiData.power_matches.map((item, index) => (
              <li key={index}>{item.label}: {item.duration}</li>
            ))}
          </ul>
          <Button
            onClick={handleDataModalClose}
            variant="outlined"
            color="secondary"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default RecordingsTable;