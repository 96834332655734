import { useEffect, useRef, useState } from 'react';
import { CapturedData, Recording } from '../types';
import calculateEnergy from '../helper/energy-calculator-utils';
import { useGlobalState } from '../context/StateContext';

const localStorageKey = 'recordings';

export function DataRecorderHook() {
  const {
    capturedData,
    setRecordings,
    updateRecordings,
    recordings,
    currentRecording,
    setCurrentRecording,
    isRecording,
    setIsLabelModalOpen,
    isLabelModalOpen,
    currentRecordingLabel,
  } = useGlobalState();
  const [startRecordingDate, setStartRecordingDate] = useState<Date | null>(null);
  const [isRecordingLoaded, setIsRecordingLoaded] = useState<boolean>(false);

  useEffect(() => {
    console.log('recordings', recordings);
  }, [recordings]);

  const filterCapturedData = (
    capturedData: CapturedData[],
    fromDate: Date,
  ) => {
    return capturedData.filter(
      (data) => new Date(data.creation) >= fromDate,
    );
  };

  const prevIsRecordingRef = useRef(isRecording);

  useEffect(() => {

    const startRecording = () => {
      setStartRecordingDate(new Date());
    };

    const stopRecording = () => {
      // const energy = calculateEnergy(currentRecording);
      // const recording: Recording = {
      //   recordedData: currentRecording,
      //   label: '(none)',
      //   energy: energy,
      // };

      // setStartRecordingDate(null);
      // setCurrentRecording([]);
      setIsLabelModalOpen(true);
      // updateRecordings((prev) => [...prev, recording]);
    };

    if (prevIsRecordingRef.current && !isRecording) {
      stopRecording();
    } else if (!prevIsRecordingRef.current && isRecording) {
      startRecording();
    }
    prevIsRecordingRef.current = isRecording;
  }, [isRecording, currentRecording, setCurrentRecording, updateRecordings]);


  useEffect(() => {

    if (!isLabelModalOpen && currentRecording.length > 0) {
      const energy = calculateEnergy(currentRecording);
      console.log('currentRecordingLabel', currentRecordingLabel);
      const recording: Recording = {
        recordedData: currentRecording,
        label: currentRecordingLabel || '(none)',
        energy: energy,
      };

      setStartRecordingDate(null);
      setCurrentRecording([]);
      updateRecordings((prev) => [...prev, recording]);
    }
  }, [isLabelModalOpen, currentRecordingLabel]);

  useEffect(() => {
    const savedRecordings = localStorage.getItem(localStorageKey);
    if (savedRecordings) {
      setRecordings(JSON.parse(savedRecordings));
    }
    setIsRecordingLoaded(true);
  }, [setRecordings, setIsRecordingLoaded]);

  useEffect(() => {
    if (isRecordingLoaded) {
      console.log('saving recordings', recordings);
      localStorage.setItem(localStorageKey, JSON.stringify(recordings));
    }
  }, [isRecordingLoaded, recordings]);

  useEffect(() => {
    if (isRecording) {
      const recoding = filterCapturedData(
        capturedData,
        startRecordingDate!,
      );
      setCurrentRecording(recoding);
    }
  }, [capturedData, isRecording, startRecordingDate, setCurrentRecording]);
}
